import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'react-phone-number-input/style.css';
import primaryAbstractBackgroundImage from '../../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import EmailConfirmationSummaryBox from './EmailConfirmationSummaryBox/EmailConfirmationSummaryBox';

function EmailConfirmationView() {

  // #region Vars and Consts
  
  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);
  const [userName, setUserName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  // #endregion

  // #region Events

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    confirmEmail(uuid);  
  }, []);

  // #endregion

  // #region Methods

  const hasUUID = (uuid) => {
    return (uuid !== null && uuid !== undefined && uuid.trim() !== '')
  };

  const confirmEmail = (uuid) => {
    if (!hasUUID(uuid)) {
      showAlert('Sin el código no es posible continuar con la confirmación de correo. Por favor verifique su bandeja de entrada y entre al enlace proporccionado para poder confirmar el Correo electrónico.');
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/confirmEmail?uuid=${uuid}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then(response => response.json()).then(data => {
        if (data.responseCode === 'OK') {
          setUserEmail(data.responseObject.email);
          setUserName(data.responseObject.name);
          setUserLastName(data.responseObject.lastName);          
          showEmailConfirmationSummary();
        } else {
          showAlert('Hubo un problema al confirmar el correo electrónico, por favor intente más tarde.');
        }
      }, (error) => {
        showAlert('Error al confirmar correo electrónico.');
      });      
    }
  };

  const showEmailConfirmationSummary = () => {
    setIsEmailConfirmed(true);
  };  

  const showAlert = (errorMessage) => {
    setErrorMessage(errorMessage);
    setIsAlertVisible(true);
  };

  // #endregion

  // #region Templates

  const alertTemplate = (
    <>
      <Alert
        key='warning'
        variant='warning'
        hidden={!isAlertVisible}
      >
        {errorMessage}
      </Alert>
    </>
  );

  const emailConfirmedSummaryTemplate = (
    <>
      <div className='pt-5'>
        <EmailConfirmationSummaryBox
          name={userName}
          email={userEmail}
        />
      </div>
    </>
  )

  // #endregion

  return (
    <>
      <article
        className='app-sign-up-view app-has-navbar app-has-footer'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <section className='pt-5 pb-5'>
          <Row>
            <Col
              xs={12}
              sm={{ offset: 1, span: 10 }}
              md={{ offset: 2, span: 8 }}
              lg={{ offset: 3, span: 6 }}
              xl={{ offset: 4, span: 4 }}
            >
              <h1 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center'>
                Confirmación de correo electrónico
              </h1>
              {alertTemplate}
              {isEmailConfirmed && emailConfirmedSummaryTemplate}
            </Col>
          </Row>
        </section>
      </article>
    </>
  );
}

export default EmailConfirmationView;