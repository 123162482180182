import { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import PaymentSucccessDetailBox from './PaymentSucccessDetailBox/PaymentSucccessDetailBox';
import primaryAbstractBackgroundImage from '../../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import { useSearchParams } from 'react-router-dom';

function PaymentSucccessView() {
  // #region Vars and Consts

  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [programName, setProgramName] = useState(null);
  const [price, setPrice] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);

  // #endregion

  // #region Events

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    getPayment(uuid);  
  }, []);

  // #endregion

  // #region Methods

  const hasUUID = (uuid) => {
    return (uuid !== null && uuid !== undefined && uuid.trim() !== '')
  };

  const getPayment = (uuid) => {
    if (!hasUUID(uuid)) {
      showAlert('Sin el código no es posible continuar con la verificación de pago.');
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/conekta/successfulPayment?orderUuid=${uuid}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then(response => response.json()).then(data => {
        if (data.responseCode === 'OK') {
          setProgramName(data.responseObject.programName);
          setPrice(data.responseObject.price);
          setOrderNumber(data.responseObject.orderNo);
          showPaymentConfirmation();
        } else {
          showAlert('Hubo un problema al confirmar el pago, disculpe las molestias.');
        }
      }, (error) => {
        showAlert('Error al confirmar pago.');
      });
    }
  };

  const showPaymentConfirmation = () => {
    setIsPaymentSuccessful(true);
  };

  const showAlert = (errorMessage) => {
    setErrorMessage(errorMessage);
    setIsAlertVisible(true);
  };

  // #endregion

  // #region Templates

  const alertTemplate = (
    <>
      <Alert
        key='warning'
        variant='warning'
        hidden={!isAlertVisible}
      >
        {errorMessage}
      </Alert>
    </>
  );

  const paymentSuccessTemplate = (
    <>
      <PaymentSucccessDetailBox
        productName={programName}
        price={price}
        orderNumber={orderNumber}
      />
    </>
  );

  // #endregion

  return (
    <>
      <article
        className='app-neutral-white-bg app-has-navbar app-has-footer'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <section className='pt-5 pb-5'>   
          <Row>
            <Col
              xs={12}
              sm={{ offset: 1, span: 10 }}
              md={{ offset: 2, span: 8 }}
              lg={{ offset: 3, span: 6 }}
              xl={{ offset: 4, span: 4 }}
              className='app-secondary-bg pt-5 pb-5'
              style={{
                borderRadius: '2rem'
              }}
            >
              <h1 className='app-pharma-bold-font app-primary-text text-uppercase text-center pb-3'>
                Confirmación de Pago
              </h1>
              {alertTemplate}
              {isPaymentSuccessful && paymentSuccessTemplate}              
            </Col>
          </Row>
        </section>        
      </article>
    </>
  );
}

export default PaymentSucccessView;