import { Button, Col, Container, Row } from 'react-bootstrap';
import headerImage from '../../../../../../assets/images/blogs/la-gran-vida-blog-que-hay-en-el-libro-de-header-image.png';
import underlineWhiteIamge from '../../../../../../assets/images/underlines/underline-white.png';
import * as Viewports from "../../../../../../constants/viewports";
import { AppContext } from '../../../../../../context/appContext';
import { useContext } from 'react';

function HeroBannerBox() {

  // #region Vars and Consts

  const { viewport } = useContext(AppContext);
  const underlineStyles = {
    SMALL: {
      width: '45%',
      left: '35%',
      bottom: '10%'
    },
    MEDIUM: {
      width: '35%',
      left: '40%',
      bottom: '20%'
    },
    LARGE: {
      width: '25%',
      left: '45%',
      bottom: '30%'
    }
  };

  // #endregion

  // #region Methods

  const getUnderlineStyle = (viewport) => {    
    let style;
    switch (viewport) {
      case Viewports.sizes.EXTRA_SMALL:
      case Viewports.sizes.SMALL:
        style = underlineStyles.SMALL;
        break;
      case Viewports.sizes.MEDIUM:
      case Viewports.sizes.LARGE:
        style = underlineStyles.MEDIUM;
        break;
      case Viewports.sizes.EXTRA_LARGE:
      case Viewports.sizes.EXTRA_EXTRA_LARGE:
      default:  
        style = underlineStyles.LARGE;
        break;
    }
    return style;
  };

  // #endregion


  // #region Templates
  
  const heroBannerTemplate = (
    <>
      <Container
        fluid
        style={{
          background: `url(${headerImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'      
        }}
      >
        <Row>
          <Col className='position-relative'>
            <h1
              className='app-primary-text app-pharma-bold-font text-uppercase text-center'
              style={{
                fontSize: '3rem',                
                marginTop: '10%',
                marginBottom: '10%'
              }}
            >
              ¿Qué hay en el libro?
            </h1>
            <img
              className='position-absolute'
              src={underlineWhiteIamge}
              style={getUnderlineStyle(viewport)}
            />        
          </Col>
        </Row>        
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {heroBannerTemplate}
        </section>
      </article>
    </>
  );
}

export default HeroBannerBox;