import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import * as Viewports from "../../../../../../../constants/viewports";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function ProgressCard({  
  completed,
  progress,
  buttonLabel,
  onButtonPress
}) {

  // #region Events

  const onButtonPressEvent = () => {    
    if (onButtonPress && onButtonPress instanceof Function) {
      onButtonPress();
    }
  };

  // #endregion

  // #region Templates

  const progressTemplate = (
    <>
      <p className='display-4'>
        {`${progress} %`}&nbsp;
        {
          completed && (
            <FontAwesomeIcon
              icon={faCheck}
            />
          )
        }
      </p>
    </>
  );

  const moduleEntranceTemplate = (
    <>
      <Button
        className={`text-uppercase app-pharma-bold-font ms-auto d-block text-end ${completed ? 'app-quinary-text' : 'app-primary-text'}`}
        variant='link'
        size={Viewports.sizes.LARGE}
        onClick={onButtonPressEvent}
        style={{
          textDecoration: 'none'
        }}
      >
        {completed ? 'Entrar' : progress === 0? 'Comenzar': 'Continuar'}&nbsp;
        <FontAwesomeIcon
          icon={faChevronRight}
        />
      </Button>
    </>
  );

  const cardTemplate = (
    <>
      {
        <div
          className={`p-4 app-secondary-bg ${completed ? 'app-quinary-text' : 'app-primary-text'} app-pharma-bold-font text-uppercase`}
          style={{
            borderRadius: '2rem'
          }}
        >
          <h1>
            Avance:
          </h1>
          <Row className='align-items-end'>
            <Col xs={12} sm={8} md={12} lg={12} xl={8}>
              {progressTemplate}
            </Col>
            <Col xs={12} sm={4} md={12} lg={12} xl={4}>
              {moduleEntranceTemplate}
            </Col>
          </Row>
        </div>
      }
    </>
  );

  // #endregion

  return (
    <>
      {cardTemplate}
    </>
  );
}

export default ProgressCard;
