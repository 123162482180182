import { Col, Row } from 'react-bootstrap';
import * as Paths from "../../../../../../../constants/paths";
import { useNav } from '../../../../../../../hooks/useNav';
import ModuleLink from './ModuleLink/ModuleLink';

function ModulesCard({
  programId,
  modules  
}) {

  // #region Vars and Consts

  const nav = useNav();

  // #endregion

  // #region Events

  const onModulePress = (programId, moduleId) => {    
    goToModule(programId, moduleId);
  };

  // #endregion

  // #region Methods

  const goToModule = (programId, moduleId) => {
    nav.navigate(
      Paths.views.user.MY_PROGRAM_MODULE,
      null,
      {
        ':programId': programId,
        ':moduleId': moduleId
      }
    );
  };

  // #endregion

  // #region Templates

  const moduleTemplate = (module, index) => {
    return (
      <>
        <Row
          key={`module_${index}`}
          className='pt-1 pb-1'
        >
          <Col>
            <ModuleLink               
              description={module.description}
              disable={!module.available}
              completed={module.completed}
              onLinkPress={() => { onModulePress(programId, module.moduleId) }}
            />
          </Col>
        </Row>
      </>
    )
  };

  const modulesListTemplate = (
    <>
      {
        (modules && modules.length > 0) && (
          modules.map((module, index) => {
            return moduleTemplate(module, index);
          })
        )
      }
    </>
  );

  const cardTemplate = (
    <>
      <div
        className='p-4 app-tertiary-bg'
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '2rem'
        }}
      >
        <h1 className='app-secondary-text app-pharma-bold-font text-uppercase'>
          Módulos
        </h1>
        {modulesListTemplate}
      </div>
    </>
  );

  // #endregion

  return (
    <>
       {cardTemplate}
    </>
  );
}

export default ModulesCard;
