import { Button, Col, Container, Row } from 'react-bootstrap';
import aboutUsImage from '../../../../../../assets/images/about-us/la-gran-vida-quienes-somos.png';
import underlineWhiteIamge from '../../../../../../assets/images/underlines/underline-white.png';
import * as Paths from "../../../../../../constants/paths";

function HeroBannerBox() {

  // #region Templates
  
  const heroBannerTemplate = (
    <>
      <Container
        id={Paths.sections.aboutUs.WHO_ARE_WE}
        fluid
        className='app-secondary-bg'
      >
        <Row>
          <Col
            xs={12}
            lg={5}
            style={{
              backgroundImage: `url(${aboutUsImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          >
            <div
              style={{
                height: '400px'
              }}
            >
            </div>
          </Col>
          <Col
            xs={12}
            lg={7}
            className='p-5'
          >
            <h2
              className='app-primary-text app-suomi-hand-script-font'
              style={{
                fontSize: '3rem'
              }}
            >
              ¿Quienes somos?
            </h2>
            <img
              className='w-25'
              src={underlineWhiteIamge}
            />
            <p className='app-montserrat-medium-font app-neutral-white-text fs-4'>
              Somos un grupo de terapeutas que decidimos poner nuestros conocimientos, experiencias y prácticas de acompañamiento al servicio de los demás a través de esta idea de La Gran Vida, con la intención de generar un impacto positivo en las personas que lo visiten y se animen a transitarlo. ¡Bienvenido!
            </p>
            <p className='app-montserrat-medium-font app-primary-text fs-4'>
              <i>
                "Ninguno de nosotros es tan bueno, como todos nosotros juntos."
              </i>
            </p>
            <p className='app-pharma-bold-font app-neutral-white-text fs-4 text-end text-uppercase'>
              Ray Rock
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {heroBannerTemplate}
        </section>
      </article>
    </>
  );
}

export default HeroBannerBox;