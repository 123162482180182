import { Button, Col, Container, Row } from 'react-bootstrap';
import bookDejaDeSerTuIamge from '../../../../../../assets/images/books/la-gran-vida-libro-deja-de-ser-tú-de-joe-dispenza.jpg';
import bookHerramientasDeRelacionamientoImage from '../../../../../../assets/images/books/la-gran-vida-libro-herramientas-de-relacionamiento-oscar-navarro.jpg';
import bookLaBiologiaDeLaCreenciaImage from '../../../../../../assets/images/books/la-gran-vida-libro-la-biología-de-la-creencia-de-bruce-lipton.jpg';
import bookLaBiologiaDeLaTransformacionImage from '../../../../../../assets/images/books/la-gran-vida-libro-la-biología-de-la-transformación-de-bruce-lipton.jpg';
import bookSinLimitesImage from '../../../../../../assets/images/books/la-gran-vida-libro-sin-límites-outlive-peter-attia.jpg';
import * as Viewports from "../../../../../../constants/viewports";
import { AppContext } from '../../../../../../context/appContext';
import { useContext } from 'react';

function BlogDetailsBox() {

  // #region Vars and Consts

  const { viewport } = useContext(AppContext);
  const imageStyles = {
    SMALL: {
      width: '100%'
    },
    MEDIUM: {
      width: '33%',
    },
    LARGE: {
      width: '15%',
    }
  };

  // #endregion

  // #region Methods

  const getImageStyle = (viewport) => {
    let style;
    switch (viewport) {
      case Viewports.sizes.EXTRA_SMALL:
        style = imageStyles.SMALL;
        break;
      case Viewports.sizes.SMALL:
      case Viewports.sizes.MEDIUM:
        style = imageStyles.MEDIUM;
        break;
      case Viewports.sizes.LARGE:
      case Viewports.sizes.EXTRA_LARGE:
      case Viewports.sizes.EXTRA_EXTRA_LARGE:
      default:
        style = imageStyles.LARGE;
        break;
    }
    return style;
  };

  // #endregion

  // #region Templates

  const paragraphImageTemplate = (image) => {
    return (
      <>
        <img
          className={`float-start shadow ${viewport === Viewports.sizes.EXTRA_SMALL? 'mb-3': 'm-3'}`}
          src={image}
          style={getImageStyle(viewport)}
        />
      </>
    )
  };

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          Herramientas de relacionamiento de Oscar Navarro
        </h1>         
        <Row>          
          <Col>
            {paragraphImageTemplate(bookHerramientasDeRelacionamientoImage)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Dentro de este libro encontrarás una serie de herramientas que el autor ha ido desarrollando en sus años de vida y terapia. Si bien, muchas de las técnicas descritas ahí podrían parecer de sentido común o de uso regular por terapeutas, éstas reflejan su visión y su versión de ellas.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Para el lector pueden ser un apoyo y referencia para afrontar, de una manera diferente, creativa y constructiva, situaciones de conflicto que se pudieran presentar en su día a día. La forma de presentar las herramientas se refresca con el complemento de frases de algunos autores reconocidos, así como de algunos refranes que pueden ayudar a recordar las herramientas, alimentando también la curiosidad del lector.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              El autor es muy cauteloso al hacer énfasis en que el contenido de este libro ha dado muy buenos resultados en aquellos que utilizan las herramientas o técnicas ahí descritas; de ninguna manera el material pretende sustituir o remplazar el acompañamiento y apoyo de un psicoterapeuta.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Es un libro fácil de leer, sencillo para entender y útil al aplicar.
            </p>
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          La biología de la transformación de Bruce Lipton
        </h1> 
        <Row>
          <Col>
            {paragraphImageTemplate(bookLaBiologiaDeLaTransformacionImage)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En este Libro de Bruce Lipton él plantea que los puntos de vista, las creencias y los razonamientos antiguos no nos ayudarán a solucionar la situación actual ni a alcanzar una nueva. Necesitamos un nuevo paradigma. Necesitamos una evolución espontánea.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En el libro se forma la idea de que nuestro planeta experimentará una remisión espontánea una vez que aceptemos nuestra responsabilidad, y nos enseña que todos podemos cambiar la programación, tanto la propia como la de nuestra civilización, para crear el mundo que hemos soñado.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En contraposición a lo que la ciencia y la religión nos han dicho siempre, la evolución no es ni aleatoria ni predeterminada, sino una danza bastante inteligente entre los organismos y el entorno. Cuando las condiciones son las propicias, ya sea por un momento de crisis o una casualidad, ocurre algo impredecible que genera un nuevo equilibrio en la biosfera, un equilibrio con un nivel superior de coherencia o cohesión. Durante milenios, los maestros espirituales nos han señalado la dirección del amor. Ahora la ciencia confirma esa antigua sabiduría.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              ¡LA EVOLUCIÓN YA HA COMENZADO! UN NUEVO MUNDO ESTÁ NACIENDO, BASADO EN EL AMOR Y NO EN EL MIEDO. Y TODOS NOSOTROS ESTAMOS CONTRIBUYENDO A SU CREACIÓN.
            </p>
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          Sin límites (Outlive) de Peter Attia
        </h1> 
        <Row>
          <Col>
            {paragraphImageTemplate(bookSinLimitesImage)}
            <h4 className='app-montserrat-semi-bold-font app-neutral-black-text py-2'>
              La nueva medicina para vivir más tiempo y mejor, un nuevo enfoque sobre la longevidad y la buena salud a largo plazo que desafía el pensamiento médico convencional
            </h4>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              A pesar de los grandes éxitos que la medicina ha alcanzado en muchas áreas, la realidad es que sus avances en la lucha contra las enfermedades relacionadas con la edad no son tantos. Con frecuencia las intervenciones y los tratamientos médicos ofrecen más años de vida al paciente, pero a costa de su salud o su calidad de vida. Por esta razón, el autor aboga por dejar atrás este marco anticuado y apostar por una estrategia de longevidad proactiva y personalizada, centrada en actuar ahora en lugar de esperar.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En este innovador manifiesto sobre cómo vivir mejor durante más tiempo, el Dr. Peter Attia, actualmente uno de los más destacados expertos en longevidad, propone acciones nutricionales, técnicas para optimizar el ejercicio físico y el sueño, y herramientas para mejorar la salud emocional y mental que cambiarán nuestra vida.
            </p>
            <h4 className='app-montserrat-semi-bold-font app-neutral-black-text py-2'>
              La mejor ciencia médica para prolongar la vida y mejorar la salud física, cognitiva y emocional
            </h4>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En Sin límites (Outlive) descubrirás entre otras muchas cosas:
            </p>
            <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <li>Porqué el ejercicio es el «fármaco» más potente contra el envejecimiento.</li>
              <li>Porqué has de centrarte en la bioquímica nutricional y utilizar la tecnología y los datos médicos para personalizar tus patrones alimentarios, en lugar de ceñirte a dietas estrictas.</li>
              <li>Porqué es esencial cuidar de la salud emocional además de la salud física.</li>
            </ul>
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          Deja de ser tú de Joe Dispenza
        </h1> 
        <Row>
          <Col>
            {paragraphImageTemplate(bookDejaDeSerTuIamge)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Joe Dispenza participó en la película ¿Y tú qué sabes?, un documental sobre la sobrecogedora capacidad de la mente para transformar la realidad, y cuyo impacto fue tan grande que sin publicidad recorrió miles de hogares gracias a la recomendación de boca en boca.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En este libro, el autor profundiza en todos aquellos temas que tanto nos cautivaron como la física cuántica, la neurociencia, la biología y la genética para enseñarnos a reprogramar el cerebro y ampliar nuestro marco de realidad.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              El resultado es un método práctico de trasformación para crear prosperidad y riqueza, pero también un viaje prodigioso a un nuevo estado de conciencia.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Deja de ser tú, te invita a que sueltes todas las creencias que tienes sobre ti, para que desde ahí puedas ser parte del “todo” y alcanzar la vida que deseas.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Es uno de los libros consentidos en La Gran Vida, siendo Joe Dispenza uno de nuestros maestros de transformación personal.
            </p>
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          La Biología de la Creencia de Bruce Lipton
        </h1> 
        <Row>
          <Col>
            {paragraphImageTemplate(bookLaBiologiaDeLaCreenciaImage)}
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              La biología de la creencia es un libro revolucionario en el campo de la biología moderna. Su autor, un prestigioso biólogo celular, describe con precisión las rutas moleculares a través de las cuales nuestras células se ven afectadas por nuestros pensamientos gracias a los efectos bioquímicos de las funciones cerebrales.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Con un lenguaje sencillo, múltiples ilustraciones, humor y ejemplos actuales, Bruce Lipton explica que los genes y el ADN no controlan nuestra biología, sino que es el ADN el que está controlado por las señales procedentes del medio externo celular, entre las que destacan los poderosos mensajes que provienen de nuestros pensamientos positivos y negativos.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              De esta manera, nuestro cuerpo puede cambiar realmente si reeducamos nuestra forma de pensar.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              En la nueva edición ampliada y actualizada encuentra por fin la verificación científica de la unidad cuerpo-mente-espíritu, y sus aplicaciones en tu propio cuerpo.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default BlogDetailsBox;