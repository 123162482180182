import { useContext } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import program40DaysImage from '../../../../../../assets/images/programs/la-gran-vida-program-40-dias-para-una-gran-vida-intrernal.jpg';
import underlineWhiteImage from '../../../../../../assets/images/underlines/underline-white.png';
import * as Paths from "../../../../../../constants/paths";
import * as Program from "../../../../../../constants/program";
import * as Viewports from "../../../../../../constants/viewports";
import { AppContext } from '../../../../../../context/appContext';
import { useNav } from '../../../../../../hooks/useNav';

function HeroBannerBox() {

  // #region Vars and Consts

  const nav = useNav();
  const { viewport } = useContext(AppContext);
  
  // #endregion

  // #region Events

  const onEnrolllNowPress = () => {
    goToInvestmentSection();
  };

  // #endregion

  // #region Methods

  const goToInvestmentSection = () => {
    nav.navigate(
      Paths.views.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE,
      Paths.sections.programs.fourtyDaysForAnAmazingLife.INVESTMENT
    );
  };

  // #endregion

  // #region Templates

  const heroBannerTemplate = (
    <>
      <Container
        fluid
        className='app-danger-bg py-5'
      >
        <Row>
          <Col
            xs={12}
            md={{ span: 5, offset: 1 }}
            lg={{ span: 4, offset: 1 }}
            xl={{ span: 4, offset: 1 }}
            xxl={{ span: 3, offset: 2 }}
            className='text-right position-relative d-none d-md-block'            
          >
            <img
              className='w-75 position-absolute start-50 translate-middle shadow'
              src={program40DaysImage}
              style={{
                borderRadius: '2rem',
                top: '70%'
              }}
            />
          </Col>
          <Col
            xs={12}
            md={5}
            lg={6}
            xl={6}
            xxl={5}    
            className={viewport === Viewports.sizes.EXTRA_SMALL || viewport === Viewports.sizes.SMALL? 'text-center': ''}
          >
            <Row>
              <Col>
                <img
                  className='w-50'
                  src={Program.programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE.logo}
                />
              </Col>
            </Row>
            <Row>
              <Col className='position-relative'>
                <h2
                  className='app-quaternary-text app-suomi-hand-script-font position-relative'
                  style={{
                    fontSize: '3rem'
                  }}
                >
                  ¿Qué vas a encontrar?             
                </h2>
                <img
                  className='d-none d-md-block w-50 position-absolute'
                  src={underlineWhiteImage}
                  style={{
                    left: '15px',
                    bottom: '-20px'
                  }}
                />
                <img
                  className='ms-auto me-auto d-block  d-md-none w-50'
                  src={underlineWhiteImage}                  
                />    
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="text-uppercase app-accent-button app-pharma-bold-font fs-3 p-3 mt-4 shadow"
                  variant='primary'
                  size={Viewports.sizes.LARGE}
                  onClick={onEnrolllNowPress}
                >
                  ¡Inscríbete ya!
                </Button>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}          
            className='text-right d-block d-md-none'
          >
            <img
              className='w-100 shadow p-4'
              src={program40DaysImage}
              style={{
                borderRadius: '5rem'
              }}
            />
          </Col>
        </Row>        
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          {heroBannerTemplate}
        </section>
      </article>
    </>
  );
}

export default HeroBannerBox;