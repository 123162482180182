import React, { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as yup from 'yup';
import * as Paths from "../../../../../../constants/paths";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import '../SignUpView.scss';
import SignUpSummaryBox from './SignUpSummaryBox/SignUpSummaryBox';
import * as Viewports from "../../../../../../constants/viewports";

function SignUpForm() {

  // #region Vars and Consts

  const { login } = useAuth();
  const [alertWarningIsVisible, setAlertWarningIsVisible] = useState(false);
  const nav = useNav();
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [email, setEmail] = useState(null);
  const [emailConfirmation, setEmailConfirmation] = useState(null);
  const [name, setName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState(null);
  const [agree, setAgree] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState(null);
  const [phoneCountryCallingCode, setPhoneCountryCallingCode] = useState(null);
  const [isSignUpComplete, setIsSignUpComplete] = useState(false);
  const validationSchema = yup.object().shape({
    email: yup.string()
      .required('El Correo electrónico es requerido.'),
    emailConfirmation: yup.string()
      .required('La Confirmación de Correo electrónico es requerida.')
      .oneOf([yup.ref('email'), null], 'Por favor asegurate que tu Correo electrónico y Confirmacción de Correo electrónico coincidan.'),    
    name: yup.string()
      .required('El Nombre es requerido.'),
    lastName: yup.string()
      .required('El Apellido es requerido.'),
    password: yup.string()
      .required('La Contraseña es requerida.')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*&#])[A-Za-z\d@$!%*&#]+$/, 'La contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y uno de los siguientes caracteres especiales @ $ ! % * & #'),
    passwordConfirmation: yup.string()
      .required('La Confirmación de Contraseña es requerida.')
      .oneOf([yup.ref('password'), null], 'Por favor asegurate que tu Contraseña y Confirmacción de Contraseña coincidan.'),        
    agree: yup.bool()
      .oneOf([true], "Es necesario estar deacuerdo con los Términos y Condiciones.")
      .required("Es necesario estar deacuerdo con los Términos y Condiciones.")    
  });

  // #endregion

  // #region Events

  useEffect(() => {
    scrollToTop();
  }, []);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onEmailConfirmationChange = (e) => {
    setEmailConfirmation(e.target.value);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onPhoneChange = (value) => {
    setPhone(value);
  };

  const onPhoneCountryChange = (country, countryCallingCode) => {
    setPhoneCountry(country);
    setPhoneCountryCallingCode(countryCallingCode);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onPasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const onAgreeChanged = (e) => {
    setAgree(!agree);
  };

  const onSignUpPress = (e) => {
    signUp(
      email,
      emailConfirmation,
      name,
      lastName,
      phone,
      password,
      passwordConfirmation,
      agree
    );
  };

  // #endregion

  // #region Methods

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  const signUp = async (
    email,
    emailConfirmation,
    name,
    lastName,
    phone,
    password,
    passwordConfirmation,
    agree
  ) => {
    
    resetAlerts();
    let errorMessages = [];

    const data = {
      email,
      emailConfirmation,
      name,
      lastName,
      password,
      passwordConfirmation,
      agree
    }

    if (!isPossiblePhoneNumber(phone ? phone : '')) {
      errorMessages.push('El Número telefónico no es válido.');      
    }

    validationSchema.validate(data, { abortEarly: false })
      .then(valid => {
        if (errorMessages.length > 0) {          
          showAlerts(errorMessages);
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email,
              name,
              lastName,
              areaCode: 0,
              phone,
              password
            }),
          }).then(response => response.json()).then(data => {
            if (data.responseCode === 'OK') {              
              showSignUpSummary();
            } else {              
              showAlerts(['Error al registrar usuario.']);
            }
            switch (data.responseCode) {
              case 'OK':
                showSignUpSummary();
                break;
              case 'E02':
                showAlerts([data.responseMessage]);    
                break;              
              default:
                break;
            }
          }, (error) => {            
            showAlerts(['Error al registrar.']);
          });
        }
      })
      .catch(error => {
        console.error('Error', error.errors);
        errorMessages = [...error.errors, ...errorMessages];        
        showAlerts(errorMessages);
      });
  }

  const showAlerts = (errors) => {
    setErrorMessages(errors);
    setAlertWarningIsVisible(true);
    scrollToTop();
  };

  const resetAlerts = () => {
    setErrorMessages('');
    setAlertWarningIsVisible(false);
  };

  const showSignUpSummary = () => {
    setIsSignUpComplete(true);
    scrollToTop();
  };

  // #endregion

  // #region Templates

  const alertsTemplate = (
    <>
      <Alert
        key='warning'
        variant='warning'
        hidden={!alertWarningIsVisible}
      >
        {
          (errorMessages && errorMessages.length > 0) && (
            errorMessages.map((error, index) => {
              return (
                <p>
                  {error}
                </p>
              );
            })
          )
        }
      </Alert>
    </>
  );

  const emailFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Correo electrónico
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="email"
            name="email"
            placeholder="Correo electrónico"
            className='app-montserrat-regular-font app-quinary-text'
            value={email}
            onChange={onEmailChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const emailConfirmationFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Confirmación de correo electrónico
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="email"
            name="emailConfirmation"
            placeholder="Vuelve a ingresar el correo electrónico"
            className='app-montserrat-regular-font app-quinary-text'
            value={emailConfirmation}
            onChange={onEmailConfirmationChange}
            isInvalid={!!errors.emailConfirmation}
          />
          <Form.Control.Feedback type="invalid">
            {errors.emailConfirmation}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const nameFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Nombre
        </Form.Label>
        <InputGroup hasValidation>        
          <Form.Control
            type="text"
            name="name"
            placeholder="Nombre"
            className='app-montserrat-regular-font app-quinary-text'
            value={name}
            onChange={onNameChange}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const lastNameFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Apellido
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="lastName"
            placeholder="Apellido"
            className='app-montserrat-regular-font app-quinary-text'
            value={lastName}
            onChange={onLastNameChange}
            isInvalid={!!errors.lastName}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lastName}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const phoneFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Número telefónico
        </Form.Label>
        <PhoneInput
          international
          placeholder="Número telefónico"
          className='app-sign-up-view-phone-input app-montserrat-regular-font app-quinary-text'
          countryCallingCodeEditable={false}
          defaultCountry="MX"
          value={phone}
          onChange={onPhoneChange}
          onCountryChange={onPhoneCountryChange}
        />
      </Form.Group>
    </>
  );

  const passwordFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Contraseña
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="password"
            name="name"
            placeholder="Contraseña"
            className='app-montserrat-regular-font app-quinary-text'
            value={password}
            onChange={onPasswordChange}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const passwordConfirmationFormItemTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Confirmación de Contraseña
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="password"
            name="name"
            placeholder="Vuelve a ingresar tu contraseña"
            className='app-montserrat-regular-font app-quinary-text'
            value={passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            isInvalid={!!errors.passwordConfirmation}
          />
          <Form.Control.Feedback type="invalid">
            {errors.passwordConfirmation}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const agreeFormItemTemplate = (
    <>
      <Form.Check
        className='app-montserrat-semi-bold-font'
        onChange={onAgreeChanged}
        checked={agree}
        type='checkbox'
        label='Estoy deacuerdo con los Términos y Condiciones'
      />
    </>
  );

  const formTemplate = (
    <>
      <Row className="mb-3">
        <Col>
          {emailFormItemTemplate}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {emailConfirmationFormItemTemplate}
        </Col>
      </Row>      
      <Row className="mb-3">
        <Col>
          {nameFormItemTemplate}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {lastNameFormItemTemplate}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {phoneFormItemTemplate}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {passwordFormItemTemplate}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {passwordConfirmationFormItemTemplate}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          {agreeFormItemTemplate}
        </Col>
      </Row>
      <Row>
        <Col className='text-end'>
          <Button
            type="button"
            className='app-pharma-bold-font app-accent-button text-uppercase'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onSignUpPress}
          >
            Registrarme
          </Button>
        </Col>
      </Row>
    </>
  );

  const summaryTemplate = (
    <>
      <div className='pt-5'>
        <SignUpSummaryBox
          name={name}
          email={email}
        />
      </div>
    </>
  );

  // #endregion

  return (
    <>
      <Container fluid>
        {
          !isSignUpComplete ? (
            <>
              {alertsTemplate}
              {formTemplate}
            </>
          ) : summaryTemplate
        }
      </Container>
    </>
  );
}

export default SignUpForm;