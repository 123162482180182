import { Button, Col, Container, Row } from 'react-bootstrap';

function ProgramDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>        
        <h1
          className='app-suomi-hand-script-font app-primary-text pb-3 pt-5'
          style={{
            fontSize: '3rem'
          }}
        >
          Amor ¿De qué trata?
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En este pilar nos enfocaremos en las relaciones que tenemos en nuestra vida, más allá del amor de pareja (que es lo que normalmente se refiere).
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Qué puedes obtener del programa?​
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          A través de este&nbsp;
          <b className='app-neutral-black-text'>
            programa
          </b>&nbsp;
          tendrás la posibilidad de reflexionar sobre tus relaciones en general, amistad, pareja, familiar, de trabajo o sociales (por enumerar algunas), podrás observar desde diferentes puntos de vista, cómo puedes construir, fortalecer y cultivar relaciones sanas, que te ayuden y te apoyen en construir una vida en armonía alineada con tu visión de futuro.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Cómo se cursa el programa?
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cúrsalo en línea, de manera individual, con una duración recomendada en 1 semana.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Justó aquí en La Gran Vida encontrarás un espacio para cursarlo desde el lugar que elijas, en cualquier dispositivo, en los tiempos que definas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Te llevaremos de la mano para orientarte a lograr un pilar de amor adecuado para ti.
        </p>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default ProgramDetailsBox;