import { useEffect } from 'react';
import { useNav } from '../../../../../../../hooks/useNav';
import { Button, Col, Row } from 'react-bootstrap';
import * as Viewports from "../../../../../../../constants/viewports";
import * as Paths from "../../../../../../../constants/paths";

function SignUpSummaryBox({
  name,
  email
}) {

  // #region Vars and Consts

  const nav = useNav();

  // #endregion

  // #region Events

  const onGoToHome = () => {
    goToHome();
  };

  // #endregion

  // #region Methods

  const goToHome = () => {
    nav.navigate(Paths.views.HOME);
  };

  // #endregion

  // #region Templates

  const greetingTitleTemplate = (
    <>
      <h2 className='app-neutral-black-text app-montserrat-medium-font text-center'>
        ¡Gracias&nbsp;
        <b className='app-accent-text app-pharma-bold-font text-center'>
          {name}
        </b>!
      </h2>
    </>
  );

  const welcomeTemplate = (
    <>
      <p className='app-neutral-black-text app-montserrat-medium-font fs-4 text-center'>
        ¡Te damos la bienvenida a&nbsp;
        <b className='app-accent-text app-pharma-bold-font text-center'>
          La Gran Vida
        </b>!
      </p>
    </>
  );

  const pleaseCheckTemplate = (
    <>
      <p className='app-neutral-black-text app-montserrat-medium-font fs-4 text-center'>
        Por favor, revisa tu bandeja de entrada de&nbsp;
        <b className='app-accent-text app-pharma-bold-font text-center'>
          {email}
        </b>&nbsp;
        para encontrar un correo electrónico de confirmación.
      </p>
    </>
  );
  
  const exploreTemplate = (
    <>
      <p className='app-neutral-black-text app-montserrat-medium-font fs-4 text-center'>
        Mientras tanto, te invitamos a explorar nuestra plataforma y descubrir todo lo que tenemos para ofrecerte:
      </p>
      <Row>
        <Col className='text-center pt-4'>
          <Button
            className="text-uppercase app-accent-button app-pharma-bold-font fs-3 p-3 shadow"
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onGoToHome}
          >
            !Ir a La Gran Vida!
          </Button>
        </Col>
      </Row>
    </>
  );

  // #endregion

  return (
    <>
      <section>
        <Row>
          <Col className='pb-4'>
            {greetingTitleTemplate}
          </Col>
        </Row>
        <Row>
          <Col>
            {welcomeTemplate}
            {pleaseCheckTemplate}
            {exploreTemplate}
          </Col>
        </Row>
      </section>
    </>
  );
}

export default SignUpSummaryBox;
