import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../../_common/Breadcrumb/Breadcrumb";

function HeaderBanner({
  title,
  subtitle,
  breadcrumbs,
  logo
}) {

  const breadcrumbTemplate = (
    <>
      <div className="ps-4 pe-4">
        <Breadcrumb
          breadcrumbs={breadcrumbs}
        />
      </div>
    </>
  );


  const titleTemplate = (
    <>
      <h2
        className='app-warning-text ps-4 pe-4 app-suomi-hand-script-font'
        style={{
          fontSize: '3rem'
        }}
      >
        {title}
      </h2>
      <h4 className='app-accent-text ps-4 pe-4 app-pharma-bold-font text-uppercase'>
        {subtitle}
      </h4>
    </>
  );

  const logoTemplate = (
    <>
      {
        logo && (
          <img
            src={logo}
            className='w-100'
            alt={title}
          />
        )      
      }
    </>
  );

  return (
    <>
      <section className="app-danger-bg">
        <Container
          className="pt-4 pb-4"
        >
          <Row>
            <Col
              xs={8}
              sm={8}
              md={8}
              lg={9}
              xl={9}
              xxl={10}
            >
              <Row>
                <Col>
                  {breadcrumbTemplate}
                </Col>
              </Row>
              <Row>
                <Col>
                  {titleTemplate}
                </Col>
              </Row>              
            </Col>
            <Col>
              {logoTemplate}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HeaderBanner;
