import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Viewports from "../../../../../../constants/viewports";

function ComingSoonDialog({
  show,
  onHide
}) {

  // #region Events

  const onHideEvent = () => {
    hideDialog();
  };

  const onAcceptPressEvent = () => {
    hideDialog();
  };

  // #endregion

  // #region Methods

  const hideDialog = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }
  };

  // #endregion

  // #region Templates

  const modalTemplate = (
    <>
      <Modal
        show={show}
        centered
        onHide={onHideEvent}
        className='app-neutral-black-text app-montserrat-medium-font'
      >        
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title>
            Próximamente
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='app-quaternary-bg'>
          <p>
            Gracias por tu interés. ¡Muy pronto tendremos novedades sobre nuestro nuevo programa! Mantente atento.
          </p>
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          <Button
            className='app-primary-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onAcceptPressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // #endregion

  return (
    <>
      {modalTemplate}
    </>
  );
}

export default ComingSoonDialog;
