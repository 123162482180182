import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'react-phone-number-input/style.css';
import primaryAbstractBackgroundImage from '../../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import SignUpForm from './SignUpForm/SignUpForm';
import './SignUpView.scss';

function SignUpView() {

  // #region Events

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  
  // #endregion

  // #region Templates

  const formTemplate = (
    <>
      <SignUpForm />     
    </>
  );

  // #endregion

  return (
    <>
      <article
        className='app-sign-up-view app-has-navbar app-has-footer'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <section className='pt-5 pb-5'>
          <Row>
            <Col
              xs={12}
              sm={{ offset: 1, span: 10 }}
              md={{ offset: 2, span: 8 }}
              lg={{ offset: 3, span: 6 }}
              xl={{ offset: 4, span: 4 }}
            >
              <h1 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center'>
                Registro
              </h1>
              {formTemplate}
            </Col>
          </Row>          
        </section>
      </article>      
    </>
  );
}

export default SignUpView;