import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import * as Viewports from '../../../../../../../constants/viewports';
import { AppContext } from '../../../../../../../context/appContext';

function QuoteBox(props) {

  // #region Vars and Consts

  const { viewport } = useContext(AppContext);

  // #endregion

  // #region Templates

  const quoteTemplate = (
    <>
      <p className={`app-montserrat-regular-italic-font app-neutral-grey-1-text ${viewport === Viewports.sizes.EXTRA_SMALL? '' : 'fs-4'}`}>
        <FontAwesomeIcon
          icon={faQuoteLeft}
          className='app-accent-text'
          size='2x'
        />&nbsp;
        {props.quote}
      </p>
    </>
  );

  const authorTemplate = (
    <>
      <p className='app-montserrat-semi-bold-font app-neutral-black-text fs-5'>
        {props.author}
      </p>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        <section>
          <Container fluid>
            <div
              key={props.key}
              className="text-center px-md-5"
            >
              {quoteTemplate}
              {authorTemplate}
            </div>
          </Container>
        </section>
      </article>
    </>
  );
}

export default QuoteBox;