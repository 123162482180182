import { Button, Col, Container, Row } from 'react-bootstrap';

function ProgramDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pt-5'>
          Es una&nbsp;
          <b className='app-neutral-black-text'>
            posibilidad
          </b>&nbsp;
          de cambiar tu vida para bien, salir de la rutina de sufrimiento, drama y dolor que pudiera ya no ser perceptible, pues muchos se han ido sumergiendo y “acostumbrando” a esto.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Es una serie de&nbsp;
          <b className='app-neutral-black-text'>
            herramientas de crecimiento
          </b>&nbsp;
          , autodesarrollo y autoconocimiento que te impulsará hacia una vida con mayores satisfacciones y plenitud en tu salud, relaciones, finanzas y familia. Te ayudará a colocar cuatro pilares dónde construir una vida plena y feliz.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          ¡Descubre una nueva forma de cambiar tu vida!
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Qué puedes obtener del programa?
        </h1>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          A través de este&nbsp;
          <b className='app-neutral-black-text'>
            programa
          </b>&nbsp;
          tendrás la posibilidad de reflexionar sobre tu vida en aspectos como: tu felicidad, tu salud, tu economía, tus relaciones y tu desarrollo personal actual (y las limitaciones que te han impedido vivir el mundo) y el futuro que deseas crear.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Obtendrás una base en la que apoyarte para dejar atrás tus creencias, limitaciones y dudas que te impiden alinearte con la vida que buscas crear.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Podrás construir tu aprendizaje con un enfoque práctico, en el que pondrás aplicar de inmediato todo lo aprendido.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Al inscribirte tendrás acceso a todos materiales: videos, lecturas, ejercicios, retos, recomendaciones y más.
        </p>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          ¿Cómo se cursa el programa?
        </h1>        
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Cúrsalo en línea, de manera individual.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          En La Gran Vida encuentras un espacio para formarte; a tu ritmo, a tus tiempos, en cualquier dispositivo y desde el lugar que elijas.
        </p>
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
          Te llevaremos de la mano para encontrar bienestar y mayor consciencia de ti mismo y de tu relación con el mundo en el que has elegido vivir.
        </p>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default ProgramDetailsBox;