import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import * as Viewports from '../../../../../../../../constants/viewports';

function ProgressText({
  progress,
  completed
}) {

  // #region Templates

  const inProgressTextTemplate = (
    <>
      <Button
        className="app-montserrat-medium-font app-secondary-text text-start"
        variant='link'
        size={Viewports.sizes.SMALL}
        style={{
          textDecoration: 'none'
        }}
      >
        {`Avance: ${progress}%`}&nbsp;
        <FontAwesomeIcon
          icon={faChevronRight}
        />
      </Button>
    </>
  );

  const completedTextTemplate = (
    <>
      <Button
        className="app-montserrat-medium-font app-quinary-text text-start"
        variant='link'
        size={Viewports.sizes.SMALL}
        style={{
          textDecoration: 'none'
        }}
      >
        {`Avance ${progress}%`}&nbsp;
        <FontAwesomeIcon
          icon={faCheck}
        />
      </Button>
    </>
  );

  const textTemplate = (
    <>
      {completed ? completedTextTemplate : inProgressTextTemplate}
    </>
  );

  // #endregion

  return (
    <>
      {textTemplate}
    </>
  );
}

export default ProgressText;
