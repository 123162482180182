import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Viewports from "../../../../../../../constants/viewports";

function FinishModuleModal({
  show,
  onHide,
  onContinue
}) {

  // #region Events

  const onHideEvent = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }
  };

  const onCancelPressEvent = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }  
  };

  const onContinuePressEvent = () => {
    if (onContinue && onContinue instanceof Function) {
      onContinue();
    }  
  };

  // #endregion

  // #region Templates

  const modalTemplate = (
    <>
      <Modal
        show={show}
        centered
        onHide={onHideEvent}
        className='app-neutral-black-text app-montserrat-medium-font'
      >
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title>
            Confirmación de Finalizar
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='app-quaternary-bg'>
          <p>
            ¿Estás seguro que has completado el módulo?
          </p>
          <p>
            Una vez guardado no será posible modificar la información ingresada.
          </p>
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          <Button
            className='app-secondary-button text-uppercase app-pharma-bold-font'
            variant='info'
            size={Viewports.sizes.LARGE}
            onClick={onCancelPressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Cancelar
          </Button>
          <Button className='app-primary-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onContinuePressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // #endregion

  return (
    <>
      {modalTemplate}
    </>
  );
}

export default FinishModuleModal;
