import { Button, Col, Container, Row } from 'react-bootstrap';
import heroBannerPersonImage from '../../../../../../assets/images/hero-banner/la-gran-vida-logo-40-dias-person.png';
import heroBanner40DaysImage from '../../../../../../assets/images/hero-banner/la-gran-vida-logo-40-dias.png';
import * as Paths from "../../../../../../constants/paths";
import * as Viewports from "../../../../../../constants/viewports";
import { useNav } from '../../../../../../hooks/useNav';
import SocialNetworksBox from '../../../../../_common/social/SocialNetworksBox/SocialNetworksBox';
import { useAuth } from '../../../../../../hooks/useAuth';

function HeroBannerBox() {

  // #region Vars and Consts

  const nav = useNav();
  const { user } = useAuth();  

  // #endregion

  // #region Methods

  const onChangeYourLifePress = () => {
    if (!user) {
      nav.navigate(Paths.views.SIGN_UP); 
    } else {
      nav.navigate(Paths.views.user.MY_PROGRAMS); 
    }
  }

  // #endregion

  // #region Templates
  
  const heroBannerTemplate = (
    <>
      <Container>
        <Row>
          <Col
            lg={6}
            className='text-center'
          >
            <img
              src={heroBannerPersonImage}
              className='w-100 my-5'
              alt="Chica sonriendo"
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col className='text-center'>
                <img
                  src={heroBanner40DaysImage}
                  className='w-100 p-5'
                  alt="40 días para una grand vida"
                />
              </Col>
            </Row>
            <Row>
              <Col className='px-5'>                
                <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                  Cambia tu vida para bien, apoyado en las herramientas de crecimiento que La Gran Vida tiene para ti.
                </p>
                <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
                  Crea el futuro que deseas siguiendo este paso a paso.
                </p>
                <Button
                  className="text-uppercase app-pharma-bold-font fs-3 p-3 shadow"
                  variant='primary'
                  size={Viewports.sizes.LARGE}
                  onClick={onChangeYourLifePress}
                >
                  ¡Cambia tu vida hoy!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {heroBannerTemplate}
        </section>
        <section>
          <SocialNetworksBox />
        </section>
      </article>
    </>
  );
}

export default HeroBannerBox;