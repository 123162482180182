import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import * as Paths from "../../../../../../constants/paths";
import * as Viewports from "../../../../../../constants/viewports";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import SignInDialog from '../../../../../_common/signIn/SignInDialog/SignInDialog';

function EmailConfirmationSummaryBox({
  name,
  email
}) {

  // #region Vars and Consts

  const nav = useNav();
  const { user } = useAuth();
  const [isSignInDialogVisible, setIsSignInDialogVisible] = useState(false);

  // #endregion

  // #region Events

  const onSignInPress = () => {
    showSignInDialog();
  };

  const onContinuePress = () => {
    goToHome();
  };

  // #endregion

  // #region Methods

  const goToHome = () => {
    nav.navigate(Paths.views.HOME);
  };

  const showSignInDialog = () => {
    setIsSignInDialogVisible(true);
  };

  const onHideSingIn = () => {
    setIsSignInDialogVisible(false);
  };

  // #endregion

  // #region Templates

  const greetingTitleTemplate = (
    <>
      <h2 className='app-neutral-black-text app-montserrat-medium-font text-center'>
        Hola&nbsp;
        <b className='app-accent-text app-pharma-bold-font text-center'>
          {name}
        </b>!
      </h2>
    </>
  );

  const emailConfirmedTemplate = (
    <>
      <p className='app-neutral-black-text app-montserrat-medium-font fs-4 text-center'>
        Gracias por confirmar tu correo electrónico&nbsp;
        <b className='app-accent-text app-pharma-bold-font text-center'>
          {email}
        </b>&nbsp;        
      </p>
    </>
  );

  const exploreTemplate = (
    <>
      <Row>
        <Col className='text-center pt-4'>
          {
            user ? (
              <>
                <Button
                  className="text-uppercase app-accent-button app-pharma-bold-font fs-3 p-3 shadow"
                  variant='primary'
                  size={Viewports.sizes.LARGE}
                  onClick={onContinuePress}
                >
                  !Continuar en La Gran Vida!
                </Button>     
              </>
            ): (
              <>
                <Button
                  className="text-uppercase app-accent-button app-pharma-bold-font fs-3 p-3 shadow"
                  variant='primary'
                  size={Viewports.sizes.LARGE}
                  onClick={onSignInPress}
                >
                  !Iniciar sesión y continuar!
                </Button>
                <Button
                  className="app-montserrat-medium-font app-secondary-text text-start"
                  variant='link'
                  onClick={onContinuePress}
                  size={Viewports.sizes.LARGE}
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  Continuar en La Gran Vida&nbsp;
                  <FontAwesomeIcon
                    icon={faChevronRight}
                  />
                </Button>
              </>
            )
          }
        </Col>
      </Row>
    </>
  );

  // #endregion

  return (
    <>
      <section>
        <Row>
          <Col className='pb-4'>
            {greetingTitleTemplate}
          </Col>
        </Row>
        <Row>
          <Col>            
            {emailConfirmedTemplate}
            {exploreTemplate}
          </Col>
        </Row>
      </section>
      <SignInDialog
        show={isSignInDialogVisible}
        navTo={Paths.views.user.MY_PROGRAMS}
        onHide={onHideSingIn}
      />
    </>
  );
}

export default EmailConfirmationSummaryBox;
