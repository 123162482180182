import program40DaysLogo from '../assets/images/programs/la-gran-vida-program-40-dias-para-una-gran-vida-title.png';
import programAbundanceLogo from '../assets/images/programs/la-gran-vida-program-abundancia-title.png';
import programJoyLogo from '../assets/images/programs/la-gran-vida-program-gozo-title.png';
import programLoveLogo from '../assets/images/programs/la-gran-vida-program-amor-title.png';
import programWellnessLogo from '../assets/images/programs/la-gran-vida-program-bienestar-title.png';

export const programs = {
  FOURTY_DAYS_FOR_AN_AMAZING_LIFE: {
    code: 1,
    logo: program40DaysLogo
  },
  ABUNDANCE: {
    code: 2,
    logo: programAbundanceLogo
  },
  LOVE: {
    code: 3,
    logo: programLoveLogo
  },
  WELLNESS: {
    code: 4,
    logo: programWellnessLogo
  },
  JOY: {
    code: 5,
    logo: programJoyLogo
  },
};

export const status = {
  PENDING: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3
};

export const fieldType = {
  TEXT: 1,
  TEXT_BOX: 2,
  VIDEO: 3,
  IMAGE: 4,  
  TABLE: 5,
  TEXT_AREA: 6,
  SUBTITLE: 7
};

export const getProgramLogo = (programCode) => {
  let logo = null;
  switch (programCode) {
    case programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE.code:         
      logo  = programs.FOURTY_DAYS_FOR_AN_AMAZING_LIFE.logo;  
      break;
    case programs.ABUNDANCE.code:
      logo  = programs.ABUNDANCE.logo;  
      break;
    case programs.LOVE.code:
      logo  = programs.LOVE.logo;  
      break;
    case programs.WELLNESS.code:
      logo  = programs.WELLNESS.logo;  
      break;
    case programs.JOY.code:
      logo  = programs.JOY.logo;  
      break;
    default:
      break;
  }
  return logo;
};