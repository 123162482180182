import { Button, Col, Container, Row } from 'react-bootstrap';

function BlogDetailsBox() {

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          The Joe Rogan Experience
        </h1>
        <Row>
          <Col lg={6}>
            <iframe
              style={{
                borderRadius: '12px',
                width: "100%",
                minHeight: '350px'
              }}
              src="https://open.spotify.com/embed/episode/2xvmTo09BFMd6tJfJPmmvT/video?utm_source=generator"              
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            />
          </Col>
          <Col lg={6}>
            <h4 className='app-montserrat-semi-bold-font app-neutral-black-text py-2'>
              #1897 Graham Hancock & Randall Carlson
            </h4>  
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Graham Hancock es investigador, periodista y autor de más de una docena de libros, entre ellos&nbsp;
              <b className='app-neutral-black-text'>
                "Fingerprints of the Gods"
              </b>&nbsp;
              y&nbsp;
              <b className='app-neutral-black-text'>
                "America Before: The Key to Earth's Lost Civilization"
              </b>.&nbsp;
              Echa un vistazo a su nueva serie de Netflix,&nbsp;
              <b className='app-neutral-black-text'>
                "Ancient Apocalypse"
              </b>,&nbsp;
              a partir del 11 de noviembre.&nbsp;
              <b className='app-neutral-black-text'>
                Randall Carlson
              </b>&nbsp;
              es investigador, maestro de construcción, diseñador arquitectónico, geómetra y presentador del podcast&nbsp;
              <b className='app-neutral-black-text'>
                "Kosmographia"
              </b>.&nbsp;              
            </p>
            <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              <li>
                <a
                  href='https://www.grahamhancock.com'
                  target='blank'
                >
                  www.grahamhancock.com
                </a>
              </li>
              <li>
                <a
                  href='https://www.randallcarlson.com'
                  target='blank'
                >
                  www.randallcarlson.com
                </a>
              </li>              
            </ul>          
          </Col>
        </Row>
        <h1
          className='app-suomi-hand-script-font app-primary-text py-3'
          style={{
            fontSize: '3rem'
          }}
        >
          Jordan Peterson
        </h1>
        <Row>
          <Col lg={6}>
            <iframe
              style={{
                borderRadius: '12px',
                width: "100%",
                minHeight: '250px'
              }}
              src="https://open.spotify.com/embed/episode/0Oj9HbuTVKQ4xozCHObdje?utm_source=generator"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            />
          </Col>
          <Col lg={6}>
            <h4 className='app-montserrat-semi-bold-font app-neutral-black-text py-2'>
              Sentido, Depresión y el Peso del Mundo
            </h4>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4'>
              Este es un lanzamiento especial del&nbsp;
              <b className='app-neutral-black-text'>
                Beyond Order Tour
              </b>&nbsp;
              en su visita a Dublín, Irlanda. El&nbsp;
              <b className='app-neutral-black-text'>
                Dr. Jordan B. Peterson
              </b>&nbsp;
              analiza el espíritu animador del juego, el ciclo de retroalimentación positiva de la depresión, cómo perseguir lo que tiene significado y porqué abdicar de la responsabilidad resulta ser un destino.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default BlogDetailsBox;