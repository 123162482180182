import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

function PaymentSucccessDetailBox({
  productName,
  price,
  orderNumber
}) {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  const detailTemplate = (
    <>
      <Container
        className='text-end app-montserrat-medium-font app-neutral-white-text fs-4'
      >
        <>          
          <p className='text-center pb-5'>
            ¡Gracias por tu compra! Hemos recibido tu pago.
          </p>
          <h2 className='app-primary-text text-end text-uppercase pb-2'>
            Detalle de compra
          </h2>
        </>
        <p className='app-accent-text'>
          {productName} 
        </p>
        <p>
          <b>
            {`${price}`}
          </b>
        </p>
        <p>
          {`No. de Orden`}
        </p>
        <p>
          {orderNumber}
        </p>
      </Container>
    </>
  );

  return (
    <>
      <section>
        {detailTemplate}
      </section>
    </>
  );
}

export default PaymentSucccessDetailBox;