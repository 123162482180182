import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'primereact/carousel';
import { Container } from 'react-bootstrap';
import QuoteBox from './QuoteBox/QuoteBox';

function TestimonialsBox() {

  // #region Vars and Consts

  const testimonials = [
    {
      id: 1,
      testimonial: 'Tomar el curso "40 días para una Gran Vida" ha sido una experiencia transformadora para mí. Entre las herramientas recibidas están la gestión de mis finanzas de forma consciente. Además, he aprendido a estar más presente en el aquí y ahora, lo cual ha mejorado significativamente mi bienestar diario.',
      author: 'Claudia Saldaña'
    },
    {
      id: 2,
      testimonial: 'Es un curso que te invita a ver más allá de tus propias limitantes, excelente oportunidad para cambiar el chip y atreverse a cosas nuevas, ¡gracias!',
      author: 'Estefany Ibarra'
    },
    {
      id: 3,
      testimonial: 'El taller de 40 días para una Gran Vida es muy bueno porque te ayuda a conocerte, analizarte y darte cuenta de cosas que pasan en tu vida que requieres poner límites y cambios para mejorar y para tomar acción.',
      author: 'Diego Quiroga'
    },
    {
      id: 4,
      testimonial: 'Este taller me ayudó mucho a ver las cosas desde una perspectiva diferente. Además, la interacción con Óscar y Alhelí fue muy amena, y se sentía en el ambiente la libertad de expresarnos y preguntar cualquier duda.',
      author: 'Alberto Rodríguez'
    },
    {
      id: 5,
      testimonial: 'Recomiendo al 100 % el taller de "40 días para una Gran Vida". Si como yo estás en constante autoconocimiento y búsqueda de crecimiento en todas las áreas de la vida, es un taller genial que, de forma muy práctica y puntual, con su metodología me llevó a hurgar en el interior del ser, donde encontré respuestas genuinas y adecuadas para el camino que hoy estoy eligiendo vivir.',
      author: 'Karla Montes'
    },
    {
      id: 6,
      testimonial: 'La experiencia que tuve en este taller fue muy enriquecedora, sus exponentes muy amenos; yo quedé fascinada, terminé mi curso lista para aplicar en mi vida lo aprendido y con una muy linda experiencia de haber conocido personas buscando el mismo objetivo.',
      author: 'Sharon'
    },
    {
      id: 7,
      testimonial: 'La Gran Vida es una experiencia sanadora que te impulsa a seguir creciendo, te reta a ti mismo, ¡atrévete!',
      author: 'Cinthia Ibarra'
    },
    {
      id: 8,
      testimonial: 'Te brindan muchas herramientas para facilitar tus procesos de vida.',
      author: 'Diego Quiroga'
    }
  ];

  // #endregion

  // #region Templates

  const testimonialTemplate = (testimonial) => {
    return (
      <>
        <QuoteBox
          key={testimonial.id}
          quote={testimonial.testimonial}
          author={testimonial.author}
        />
      </>
    );
  };

  const carouselTemplate = (
    <>
      {
        testimonials && (
          <Container>
            <Carousel
              value={testimonials}
              numVisible={1}
              numScroll={1}
              itemTemplate={testimonialTemplate}
              circular
              autoplayInterval={25000}     
            />
          </Container>
        )
      }
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-neutral-white-bg'>
        <section>
          <h1 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center pt-5'>
            Testimoniales
          </h1>
          {carouselTemplate}
        </section>
      </article>
    </>
  );
}

export default TestimonialsBox;