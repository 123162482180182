import { useEffect } from 'react';
import ProgressText from './ProgressText/ProgressText';
import Button from 'react-bootstrap/Button';
import * as Viewports from '../../../../../../../constants/viewports';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MyProgramGridItem({  
  image,
  title,
  progress,
  completed,
  onItemPressed
}) {

  // #region Events

  useEffect(() => {

  }, []);

  const onImagePressedEvent = () => {
    if (onItemPressed && onItemPressed instanceof Function) {
      onItemPressed();
    }
  };

  const onButtonPressedEvent = () => {
    if (onItemPressed && onItemPressed instanceof Function) {
      onItemPressed();
    }
  };

  // #endregion

  // #region Templates

  const itemTemplate = (
    <>
      <div className='p-3'>
        <a
          href='javascript:void(0)'
          onClick={onImagePressedEvent}
        >
          <img
            src={image}
            alt={title}
            className='w-100 shadow'
            style={{
              borderRadius: '2rem',
            }}
          />
        </a>
      </div>
      <h4 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center'>
        {title}
      </h4>
      {
        progress === 0 ? (       
          <>
            <Button
              className="text-uppercase app-secondary-text app-montserrat-medium-font d-block ms-auto"
              variant='link'
              size={Viewports.sizes.SMALL}   
              onClick={onButtonPressedEvent}
              style={{
                textDecoration: 'none'
              }}
            >
              Entrar&nbsp;
              <FontAwesomeIcon
                icon={faChevronRight}
              />            
            </Button>
          </>
        ): (
          <ProgressText
            progress = { progress }
            completed = { completed }
          />
        )
      }      
    </>
  );

  // #endregion

  return (
    <>
      {itemTemplate}
    </>
  );
}

export default MyProgramGridItem;
