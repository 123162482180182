import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Paths from "../../../../../constants/paths";
import * as Viewports from "../../../../../constants/viewports";
import { AppContext } from '../../../../../context/appContext';
import { useAuth } from '../../../../../hooks/useAuth';
import { useNav } from '../../../../../hooks/useNav';
import HeaderBanner from '../../_common/headers/HeaderBanner/HeaderBanner';
import ModuleStepper from './ModuleStepper/ModuleStepper';
import * as Program from '../../../../../constants/program';

function MyProgramInProgressView() {

  // #region Vars and Consts

  const nav = useNav();
  const { moduleId } = useParams();
  const { viewport } = useContext(AppContext);
  const { user, logout } = useAuth();  
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [program, setProgram] = useState(null);

  // #endregion

  // #region Events

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    getProgramDetail(user.userId, moduleId);
  }, []);

  // #endregion

  // #region Methods

  const getProgramDetail = (userId, moduleId) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/userProgram/getProgramDetail?userId=${userId}&moduleId=${moduleId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {
        console.info('Program:', data);
        setProgram(data.responseObject);
        setupBreadcrumbs(data.responseObject);
      } else if (data.status === 403) {
        // Session expired
        logout();
      }
    }, (error) => {
      console.error('Error getting keep growing programs', error);
      // TODO: Show error message to the user
    });        
  };

  const setupBreadcrumbs = (program) => {
    const breadcrumbs = [{
      name: 'Mis Programas',
      navPath: `#${Paths.views.user.MY_PROGRAMS}`
    }, {
      name: program.programName,
      navPath: '#' + nav.getNavPath(Paths.views.user.MY_PROGRAM, {
        ':id': program.programId        
      })
    }, {
      name: program.moduleName      
    }];
    setBreadcrumbs(breadcrumbs);
  };
  
  const getStepperPadding = (viewport) => {
    let padding;
    switch (viewport) {
      case Viewports.sizes.EXTRA_SMALL:
      case Viewports.sizes.SMALL:
        padding = 'p-2';
        break;
      case Viewports.sizes.MEDIUM:
      case Viewports.sizes.LARGE:
      case Viewports.sizes.EXTRA_LARGE:
      case Viewports.sizes.EXTRA_EXTRA_LARGE:
        padding = 'p-5';
      default:
        padding = 'p-5';
        break;
    };
    return padding;
  };

  // #endregion

  // #region Templates

  const headerTemplate = (
    <>
      {
        program && (
          <HeaderBanner
            title={program.programName}
            subtitle={program.moduleName}
            breadcrumbs={breadcrumbs}
            logo={Program.getProgramLogo(program.programId)}
          />
        )
      }
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          {headerTemplate}
        </section>        
        <section className={`${viewport === Viewports.sizes.EXTRA_SMALL ? 'app-tertiary-bg' : 'app-neutral-white-bg'} ${getStepperPadding(viewport)}`}>
          <ModuleStepper program={program} />
        </section>        
      </article>
    </>
  );
}

export default MyProgramInProgressView;
